<template>
  <div class="login_success">
    <div class="tac">
      <img
        class="logo"
        src="https://www.zizhihelp.com/upload/logo_round.png"
        alt=""
      />

      <div class="login_success_title">登录成功!</div>
    </div>

    <div class="close_btn" @click="close">确定</div>
  </div>
</template>

<script>
import { qrLogin } from "@/api/login";

export default {
  data() {
    return {};
  },
  methods: {
    // 登录账号并获取用户信息
    async login() {
      const code = this.getParameterByName("code");
      const state = this.getParameterByName("state");
      const params = {
        code,
        state,
      };
      await qrLogin(params);
    },

    // 获取URL参数值
    getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      //匹配所有符合条件的，并取最后一个
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "g");
      var results = url.match(regex);
      var tempResults =
        results != null && results[results.length - 1] != undefined
          ? results[results.length - 1]
          : "";
      var finalResults = regex.exec(tempResults);
      if (!finalResults) return "";
      if (!finalResults[2]) return "";
      return decodeURIComponent(finalResults[2].replace(/\+/g, " "));
    },

    // 关闭窗口
    close() {
      WeixinJSBridge.call("closeWindow");
    },

    /* 初始化 */
    __init__() {
      this.login();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.login_success {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 150px 0px;

  .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  .close_btn {
    line-height: 40px;
    padding: 0 40px;
    border-radius: 4px;
    cursor: pointer;
    min-width: 60px;
    background-color: #1d89e3;
    color: #fff;
    text-align: center;
  }
}
</style>
